.help-container {
  max-width: 960px;
  margin: 60px auto;
  padding: 48px;
  background: linear-gradient(145deg, #0f172a, #1e293b);
  border-radius: 20px;
  box-shadow: 0 0 24px rgba(165, 180, 252, 0.08);
  font-family: 'Inter', 'Segoe UI', Tahoma, sans-serif;
  color: #e2e8f0;
  line-height: 1.8;
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.help-title {
  font-size: 2.5rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 40px;
  background: linear-gradient(to right, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 5px rgba(128, 90, 213, 0.2);
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 48px;
  margin-bottom: 16px;
  padding-left: 12px;
  background: linear-gradient(to right, var(--accent-gold), #fde68a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 1px 3px rgba(251, 191, 36, 0.15);
}

h2::after {
  content: '';
  display: block;
  height: 2px;
  width: 40px;
  margin-top: 6px;
  background: linear-gradient(to right, var(--accent-blue), var(--accent-purple));
  border-radius: 2px;
}

p {
  font-size: 1rem;
  margin-bottom: 18px;
  color: #cbd5e1;
}

ul,
ol {
  margin-left: 20px;
  margin-bottom: 20px;
  padding-left: 16px;
}

li {
  margin-bottom: 10px;
  font-size: 0.95rem;
  color: #dbeafe;
}

strong {
  color: var(--accent-gold);
  font-weight: 600;
}

a {
  color: var(--accent-purple);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

a:hover {
  color: var(--accent-blue);
  text-decoration: underline;
}

.closing-line {
  margin-top: 56px;
  font-size: 1.1rem;
  text-align: center;
  color: #f1f5f9;
  padding-top: 24px;
  border-top: 1px solid rgba(148, 163, 184, 0.2);
}

/* ========== RESPONSIVE ========== */
@media (max-width: 768px) {
  .help-container {
    padding: 32px 20px;
  }

  .help-title {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  p,
  li {
    font-size: 0.95rem;
  }
}

/* Prevent unwanted pseudo-elements in Help only */
.help-container section::before,
.help-container div::before {
  content: none !important;
  display: none !important;
}
