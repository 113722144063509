/* ========== DASHBOARD WRAPPER ========== */
.dashboard-page {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  color: var(--text-light);
}

/* ========== PAGE TITLE ========== */
.page-title {
  font-size: 2.4rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 36px;
  background: linear-gradient(to right, var(--accent-blue), var(--accent-purple));
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textGradient 5s ease infinite;
}

@keyframes textGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* ========== PROFILE SECTION ========== */
.profile-section {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}

.profile-pic-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--accent-blue);
  box-shadow: 0 0 12px rgba(56, 189, 248, 0.5);
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}

.upload-btn {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: var(--accent-purple);
  color: white;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 6px;
  cursor: pointer;
  z-index: 5;
}

/* ========== PROFILE INFO ========== */
.profile-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 0.95rem;
  color: #cbd5e1;
}

.profile-info span {
  color: var(--accent-gold);
  font-weight: 500;
}

.edit-name-input {
  padding: 4px 8px;
  font-size: 0.9rem;
  background: #1e1b2f;
  border: 1px solid var(--accent-blue);
  color: white;
  border-radius: 6px;
  margin-top: 4px;
  margin-right: 6px;
}

.profile-info button {
  margin-left: 6px;
  padding: 4px 10px;
  font-size: 0.85rem;
  background: var(--accent-blue);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.profile-info button:hover {
  background: #0ea5e9;
}

/* ========== METRICS ROW ========== */
.metrics-row {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 32px;
}

.metrics-row p {
  color: var(--accent-gold);
}

/* ========== FILTER SECTION ========== */
.filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: center;
  margin-bottom: 28px;
}

.search-bar,
.filter-dropdown {
  padding: 10px 14px;
  border-radius: 8px;
  background-color: #2b1b46;
  border: 2px solid #fff;
  color: #fff;
  font-size: 14.5px;
  min-width: 180px;
}

.search-bar::placeholder {
  font-style: italic;
  color: #aaa;
}

.filter-dropdown option {
  background-color: #1e1b2f;
  color: #fff;
}

/* ========== CTA GRID ========== */
.ctasContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 24px;
  margin-bottom: 50px;
}

.ctaBox {
  background: linear-gradient(135deg, #221538, #2c1f45);
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.35);
  padding: 18px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ctaBox:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 30px rgba(255, 255, 255, 0.15);
}

.ctaBox-img-cont-c1 {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  padding: 4px;
  border-radius: 12px;
}

.ctaImage {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* ========== CTA INFO ========== */
.ctaInfo {
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.ctaInfo h4 {
  font-size: 1.2rem;
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 300%;
  animation: textGradient 8s ease infinite;
  margin-bottom: 6px;
}

.bx_info_cat_c_1,
.bx_info_col_c_1,
.bx_info_rst_c_1 {
  font-size: 14px;
  color: var(--text-muted);
}

.col_prpl_c_1 {
  color: var(--accent-purple);
}

.col_glb_c_1 {
  color: var(--accent-gold);
}

.sale-status {
  padding: 6px 14px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

.sale-status.listed {
  background: linear-gradient(135deg, #5cff00, #3ccf00);
  color: var(--bg-deep);
  box-shadow: 0 0 12px rgba(92, 255, 0, 0.6);
}

.sale-status.not-listed {
  background: linear-gradient(135deg, #ff3c00, #cf2200);
  color: var(--bg-deep);
  box-shadow: 0 0 12px rgba(255, 60, 0, 0.6);
}

.btn_hpt {
  background-color: transparent;
  color: #fff;
  border: 2px solid #ffffff;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  transition: all 0.3s ease-in-out;
}

.btn_hpt:hover {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  color: #fff;
  border-color: transparent;
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.25);
}

/* ========== PAGINATION ========== */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.pagination button {
  padding: 10px 18px;
  border-radius: 6px;
  font-weight: 500;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  transition: 0.3s;
}

.pagination button:hover:not(:disabled) {
  background: var(--accent-purple);
  color: #fff;
}

.pagination button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.pagination .active-page {
  background: linear-gradient(135deg, #28233c, #382c58);
  color: var(--accent-gold);
  font-weight: 700;
  border-color: transparent;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* ========== RESPONSIVE ========== */
@media (max-width: 768px) {
  .profile-section {
    flex-direction: column;
    gap: 18px;
    text-align: center;
  }

  .metrics-row {
    flex-direction: column;
    text-align: center;
  }

  .filter-section {
    flex-direction: column;
    gap: 10px;
  }

  .search-bar,
  .filter-dropdown {
    width: 90%;
  }

  .ctaBox {
    padding: 14px;
  }
}
